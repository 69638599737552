@import "src/assets/scss/_variables";
@import "src/assets/scss/_theme";
@import "src/assets/scss/_ionic";
@import "src/assets/scss/_offers-common.scss";
@import "src/assets/scss/_tutorial.scss";

// regular style toast
@import "ngx-toastr/toastr";

// // bootstrap style toast
// // or import a bootstrap 4 alert styled design (SASS ONLY)
// // should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// @import '~ngx-toastr/toastr-bs4-alert';

// // if you'd like to use it without importing all of bootstrap it requires
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~ngx-toastr/toastr-bs4-alert';

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;600;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

p {
  user-select: none;
}

.font-black {
  font-weight: 900;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-16 {
  font-size: 16px;
}
.bg-red {
  background-color: $coral !important;
}
.bg-white {
  background-color: #fff;
  padding: 0 3px;
  border-radius: 5px;
}

.mark-yellow {
  color: $yellow;
}
.mark-red {
  color: $coral;

  .bg-red & {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 5px;
  }
}
.bg-orange {
  background-color: $orange-light;
  color: #fff;
  padding: 0 3px;
  border-radius: 5px;
}
.color-orange {
  color: $orange-light !important;
}
.color-dark-grey {
  color: #696969;
}
.color-money-green {
  color: $money-green;
}
.color-gold {
  color: $gold;
}
.mark-green {
  color: $green;
}
.color-red {
  color: $coral;
  font-weight: 600;
}

body {
  font-family: "Inter";
  color: #212529 !important;
}

.game-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  background-image: url(~src/assets/img/bg-gradient.jpeg);
  .game-bg-left-side {
    width: 50%;
    height: 100%;
    background-image: $gameBgLeft;
    background-repeat: no-repeat;
    // background-position-x: left;
    background-position-x: -102px;
    background-position-y: 50px;
  }

  .game-bg-right-side {
    width: 50%;
    height: 100%;
    background-image: $gameBgRight;
    background-repeat: no-repeat;
    background-position-x: right;

    @media (max-width: 1600px) {
      background-position-x: left;
    }
  }
}

ul,
ol {
  list-style-type: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  resize: none;
}

.d-none {
  display: none !important;
}

.wrapper {
  max-width: $max-screen;
  height: 100%;
  margin: 0 auto;
  position: relative;
  background: #fff;
  // background-color: $blue-grey-light;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.w-100 {
  width: 100%;
}

.content-box {
  height: calc(100% - 147px);
  flex: 1 1 auto;
  padding: 20px 10px 5px;

  // overflow: auto;
  @media (max-width: 400px) {
    padding: 20px 10px 5px;
  }
}

.btn {
  border-radius: 100px;
  font-size: 16px;
  line-height: 1.171875;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  @media (max-width: 320px) {
    height: 50px;
  }
}

.btn-block {
  width: 100%;
  display: flex;
}

.btn-uppercase {
  text-transform: uppercase;
}

.btn-grey {
  color: #777d87;
  background: rgba(120, 117, 136, 0.2);
}

.btn-green {
  color: #fff;
  background: rgba(34, 193, 79, 1);
}

.btn-blue {
  background: linear-gradient(180deg, #3b5998 0%, #355392 100%);
  box-shadow: 0px 2px 10px rgba(59, 89, 152, 0.55);
  color: #fff;
}

.btn-purchased {
  display: inline-flex;
  align-items: center;
  color: $ok-color;
  text-transform: uppercase;
  background-color: rgba($color: $ok-color, $alpha: 0.1);
  font-size: 15px;
  line-height: 33px;
  padding: 3px 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 500px;
  box-shadow: 0 0 0 2px $ok-color inset;

  & > svg {
    margin-left: 8px;
  }
}

.btn-primary {
  // background: linear-gradient(155.33deg, #ffa183 8.78%, #ff3975 88.79%);
  // box-shadow: 0px 4px 10px rgba(255, 65, 117, 0.5);
  color: #fff;
}

.btn-white {
  background-color: #fff;
  color: #000;
}

.form-control {
  width: 100%;
  border: none;
  background: rgba(153, 135, 254, 0.1);
  border-radius: 100px;
  color: $primary-text-color;
  height: 50px;
  padding: 0 30px 0 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &.error {
    border: 3px solid rgba(252, 53, 53, 0.15);
  }
}

textarea.form-control {
  padding: 10px;
  height: 100px;
  border-radius: 25px;
}

.form-group {
  margin-bottom: 10px;
}

.form-offset {
  margin-bottom: $offset-form;
}
.form-offset-double {
  margin-bottom: $offset-form * 2;
}

.form-group-hidden {
  display: none;
}

.form-info {
  font-size: 15px;
  line-height: 20px;
  color: #333d49;
  font-weight: 600;
  text-align: center;
}

.form-tab {
  display: flex;
  width: 213px;
  margin: 0 auto 34px;
  justify-content: space-between;

  .tab {
    color: #4e5682;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    opacity: 0.4;
    position: relative;

    &.active {
      // opacity: 1;
      // color: $primary-color;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        background-color: currentColor;
        width: 11px;
        height: 3px;
        border-radius: 2px;
      }
    }
  }
}

// router-outlet ~ * {
//   position: absolute;
//   height: 100%;
//   width: 100%;
// }

.content {
  display: none;

  &.active {
    display: block;
  }
}

.forgot-link {
  color: $primary-color;
  font-weight: 600;
  padding: 0;
  border: none;
  border-bottom: 2px solid $primary-color;
  background-color: transparent;
}

.btn-icon {
  margin-right: 12px;
}

.prize {
  color: #ffdf72;
  font-size: 18px;
  line-height: 21px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  margin-left: 12px;

  img {
    width: 33px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }
}

//////////////  FORM END ////////////////////////
.list-group {
  margin-bottom: 25px;
}

.list-group-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #333d49;
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
  background: #ffffff;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  height: 60px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.list-icon {
  margin-right: 21px;
}

html,
body {
  height: 100%;
}

.modal-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;

  &.animate-bottom {
    animation: animatebottom 0.4s;
  }

  @keyframes animatebottom {
    from {
      bottom: -600px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }
}

.modal {
  background: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 5px 5px 30px 5px;
  position: absolute; //fixed
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: $max-screen;
  width: 350px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: $z-modal;

  @media (max-width: 320px) {
    width: 300px !important;
  }

  &.modal-no-padding {
    border: 0;
    padding: 0;
    background: transparent;
  }
  &.modal-vertical {
    height: 600px;
    width: 268px;
    max-height: 100%;
    overflow: visible;

    &.modal-auto-height {
      height: auto;
    }
  }

  .modal-head {
    font-size: 21px;
    line-height: 25px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #323b46;
    background: #f5f3ff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;

    &--warning {
      color: $red-color;
      background: rgba(255, 85, 85, 0.1);
    }

    &--info {
      color: #004085;
      background-color: #cce5ff;
    }

    &--light {
      color: #888888;
      background-color: #fff;
      font-size: 16px;
      font-weight: bold;
      font-family: Tahoma, Arial, Helvetica, sans-serif;
      margin-bottom: 0;
    }
  }

  .timer-box {
    margin-bottom: 12px;
  }

  .item-img {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    padding: 4px;
    margin: 0 auto 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .timer {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    color: #ed403a;
    display: flex;
    align-items: center;
    justify-content: center;
    &.timer-info {
      color: #004085;
    }
  }

  .circle {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    background-color: rgba($color: #9987fe, $alpha: 0.1);
    border-radius: 50%;
    padding: 16px;
    position: relative;
    & > div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba($color: #f23232, $alpha: 0.12);
      padding: 9px;

      & > div {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #d7375e 0%, #f25d54 100%);
      }
    }
  }

  .info-text {
    font-weight: bold;
    text-align: center;
  }

  .info-img {
    display: block;
    margin: 0 auto;
  }

  .modal-body {
    // width: 270px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .modal-content {
    background-color: #fff;
    padding: 0 20px;
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 48px;
      border-radius: 15px;
      background-color: #f5f3ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 15px;
    }
  }

  .list-item {
    color: $primary-text-color;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 13px;
  }

  .list-item-content {
    font-size: 15px;
    line-height: 23px;
    color: $primary-text-color;

    p {
      margin-bottom: 13px;
    }
  }

  .prev,
  .close {
    width: 40px;
    height: 40px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    color: $primary-color;
  }

  .prev {
    left: 10px;
  }

  .close {
    right: 10px;
  }

  .text {
    color: $primary-text-color;
    font-size: 16px;
    line-height: 1.4375;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 130px;
    }
  }

  &--congratulations {
    background-image: url(assets/img/pattern-bg.png),
      linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    box-shadow: 0px 6px 15px rgba(252, 196, 91, 0.3);
    border: none;

    .text {
      color: #fff;

      .reward-current {
        font-size: 26px;
        line-height: 31px;
        font-size: 600;
        color: #ffdf74;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.19);
      }
    }

    .btn-primary {
      box-shadow: none;
      border: 4px solid #fff;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
      height: 62px;
    }

    .modal-head {
      background: rgba(47, 29, 99, 0.2);
      border: none;
      color: #fff;
    }

    .circle {
      background: rgba(255, 255, 255, 0.12);

      & > div {
        background: rgba(255, 255, 255, 0.35);

        & > div {
          background: transparent;
        }
      }
    }
  }

  &.modal-white-headed {
    width: 90%;
    max-width: 420px;
    background: linear-gradient(180deg, #fafbfb 25%, #f1f5f9 75%);
    .modal-head {
      font-size: 18px;
      color: #888;
      background: none;
      margin-bottom: 0;
    }
  }
}

.lead-text {
  text-align: center;
  margin: 20px 10px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  color: rgba(0, 0, 0, 0);
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 1;
}

.spinner-inline {
  width: 10px;
    display: inline-block;
    position: relative;
}

.spinner-inline:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 100%;
  width: 10px;
  height: 10px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 1;
}

.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mt30 {
  margin-top: 30px;
}

.message-list {
  height: 100%;
  display: flex;
  flex-direction: column;

  .avatar-box {
    margin-right: 15px;
    position: relative;
    flex: none;
  }
  .badge {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #ffffff;
    &--success {
      background: #2ad870;
    }
    &--warning {
      background: #d8bc2a;
    }
    &--danger {
      background: #d8492a;
    }
  }
  .message-list-item__head {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
  }
  .message-list-item__body {
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    color: rgba(115, 112, 125, 0.65);
    position: relative;
    padding-right: 10px;
  }

  .message-list-item_unlock-message {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    position: relative;
    text-align: center;
  }
  .name {
    color: $primary-text-color;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
  }
  .time {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: #c9c6d3;
    margin-left: auto;
  }
}

.message-list-item {
  height: 105px;
  min-height: 105px;
  background: #fff;
  border-radius: 11px;
  padding: 20px 13px 30px;
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
  &:last-child {
    margin-top: auto;
  }
  .avatar {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  &.bonus-item {
    padding: 15px 15px 15px 50px;

    .avatar-box {
      width: 90px;
    }

    &.received-reward {
      opacity: 0.5;
    }
  }

  .promo-click-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 15px);
    display: block;
    z-index: 199;
    color: transparent;
  }
}
.message-list-item__content {
  flex: 1 1 auto;
}
.message-list-item__side {
  margin-top: -10px;
  margin-right: -10px;
  width: 95px;
  min-width: 95px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-erogold-price,
.btn-nutakugold-price {
  padding-left: 25px !important;
  white-space: nowrap;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: url("assets/img/erogold.svg") no-repeat;
  }

  &.btn-nutakugold-price {
    &::before {
      background: url("assets/img/nutaku-gold-small.png") no-repeat;
      background-size: contain;
    }
  }
}

.transparent-modal {
  .swiper-pagination-bullets {
    height: 20px;
    top: 0;
    .swiper-pagination-bullet {
      width: 6px;
      height: 3px;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}

.display-flex {
  display: flex;
}

.tabs {
  display: flex;
  justify-content: center;
  .tab {
    &.gems-tab,
    &.secret-chats-tab {
      height: 27px;
      line-height: 27px;
      text-align: center;
      color: #7d7d7d;
      font-family: Inter;
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      position: relative;
      text-decoration: none;
      background: #f3f3f2;
      box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.06);
      border-radius: 5px;

      &.active {
        color: #efefef !important;
        background: linear-gradient(321.78deg, #ff8f3f -8.19%, #ffc93f 88.65%);
        border-radius: 5px;
        border: none;
        box-shadow: none;
        transform: scale(1.06);
        z-index: 10;
      }
    }

    &.gems-tab {
      // background-image: url(~src/assets/img/gems-btn-inactive.png) !important;
      width: 120px;
      margin-right: -5px;

      &.active {
        // background-image: url(~src/assets/img/gems-btn-active.png) !important;
      }
    }

    &.secret-chats-tab {
      // background-image: url(~src/assets/img/secret-chats-btn-inactive.png) !important;
      width: 120px;
      box-shadow: inset -2px 2px 0px rgba(0, 0, 0, 0.06);

      &.active {
        // background-image: url(~src/assets/img/secret-chats-btn-active.png) !important;
      }
    }
  }
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.height-auto {
  height: auto !important;
}

.btn-get-rewards,
.btn-run-quest {
  padding: 5px 20px;
  min-width: 150px;
  border-radius: 120px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  &.btn-get-rewards {
    background: $coral !important;
  }
  &.btn-run-quest {
    background: $coral;
  }
}

.ad-separator {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  // color: #555;
  text-align: center;
  &:before,
  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 33%;
    height: 2px;
    vertical-align: middle;
    background: #999;
    background-clip: content-box;
  }
  &:before {
    left: -0.5em;
    margin: 0 0 0 -50%;
  }
  &:after {
    left: 0.5em;
    margin: 0 -50% 0 0;
  }
}

.btn {
  position: relative;
}

.btn-blue-green {
  background: linear-gradient(130deg, #5494d5 16%, #35d562 40%);
  color: #fff;
  position: relative;
  box-shadow: 6px 6px 10px #b5fdbe;
  // font-size: 20px;
  font-weight: 600;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  min-width: 150px;
  &.no-shadow {
    box-shadow: none;
  }
  &.with-crystal::after {
    content: "";
    background: url(~src/assets/img/gem.png) no-repeat;
    position: absolute;
    width: 30px;
    height: 30px;
    background-size: contain;
    left: 16px;
    top: 16px;
  }
}
.btn-red {
  background-color: $reddish;
  color: #fff;
  min-width: 170px;
  border-radius: 20px;
  font-weight: bold;
}
.btn-red-invert {
  color: $reddish;
  background-color: #fff;
  min-width: 170px;
  border-radius: 20px;
  font-weight: bold;
}

.button-cost-badge {
  position: absolute;
  background: red;
  color: #fff;
  top: -10px;
  left: 32px;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.red-hot-button {
  height: 35px;
  font-weight: 700;
  font-size: 16px;
  background-color: #ff2e2e;
  border: 2px solid #a10707;
  color: #fff;
  border-radius: 71px;
  min-width: 130px;
  text-align: center;
}

.img-inline-button {
  width: 25px;
  vertical-align: middle;
  margin: 0 3px;
}
.inline-gem-img-small {
  width: 18px;
  vertical-align: middle;
}

.tutorial-layer {
  &.step1,
  &.step2,
  &.step6,
  &.step8 {
    pointer-events: none;

    .all-chats-container & {
      pointer-events: auto;
    }
  }
}

.swiper-pagination-bullets {
  width: 100%;
  left: auto !important;
  right: 0;
  // display: none;
}

// SHOP REDESIGN
.red-cross-line {
  position: absolute;
  transform: translateX(-50%) rotate(-23deg);
  width: 49px;
  height: 1px;
  background-color: red;
  // left: 33px;
  left: 50%;
  top: 13px;
}

.old-price {
  font-size: 14px;
  font-weight: 300;
  font-family: Inter;
}
.new-price {
  font-size: 15px;
  font-weight: 700;
  font-family: Inter;
}

// switch checkbox

$green: #2ecc71;
$lightgray: lightgray;
$background: whitesmoke;

.switch-wrap {
  margin-left: auto;
  input {
    &.mobileToggle {
      opacity: 0;
      position: absolute;
      & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        transition: 0.4s ease;
        height: 30px;
        width: 50px;
        background: #f5f3ff;
        border: 1px solid #e4e4e4;
        border-radius: 60px;
        &:before {
          content: "";
          position: absolute;
          display: block;
          transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
          height: 30px;
          width: 51px;
          top: 0;
          left: 0;
          border-radius: 30px;
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
            0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
            0 3px 3px hsla(0, 0%, 0%, 0.05);
          transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
          background: #c1bfdf;
          height: 28px;
          width: 28px;
          left: 0px;
          border-radius: 60px;
        }
      }

      &:checked {
        & + label:before {
          transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
        }

        & + label:after {
          left: 54px - 30px;
        }
      }
    }
  }
  .overlap-toggle {
    position: absolute;
    width: 51px;
    height: 100%;
    z-index: 99;
    top: 0;
  }
}

.setting-item {
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 70px;
  display: flex;
  align-items: center;
  color: #333d49;

  &.no-shadow {
    box-shadow: none;
  }
  .title {
    font-size: 16px;
    margin: 0;
    line-height: 19px;
    font-weight: 600;
  }
  .title-description {
    font-size: 12px;
    color: #9d9d9d;
  }

  &--big {
    min-height: 95px;
  }
}

// switch checkbox end// switch checkbox

$green: #2ecc71;
$lightgray: lightgray;
$background: whitesmoke;

.switch-wrap {
  margin-left: auto;
  input {
    &.mobileToggle {
      opacity: 0;
      position: absolute;
      & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        transition: 0.4s ease;
        height: 30px;
        width: 50px;
        background: #f5f3ff;
        border: 1px solid #e4e4e4;
        border-radius: 60px;
        &:before {
          content: "";
          position: absolute;
          display: block;
          transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
          height: 30px;
          width: 51px;
          top: 0;
          left: 0;
          border-radius: 30px;
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
            0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
            0 3px 3px hsla(0, 0%, 0%, 0.05);
          transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
          background: #c1bfdf;
          height: 28px;
          width: 28px;
          left: 0px;
          border-radius: 60px;
        }
      }

      &:checked {
        & + label:before {
          transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
        }

        & + label:after {
          left: 54px - 30px;
        }
      }
    }
  }
}

// switch checkbox end

.toast-container {
  pointer-events: all;
  cursor: pointer;
}

.ios-spacer {
  height: 30px;
  background: #fff;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #c2c2c2;
  animation: skeleton-appear 1s forwards;
  border-radius: 10px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(90deg, transparent, #d8d8d8, transparent);
    animation: skeleton-loader 1.75s linear infinite;
  }

  @keyframes skeleton-loader {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @keyframes skeleton-appear {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

ion-toast {
  --border-radius: 8px;
  --box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  --min-height: 54px;
  --width: 80%;
  --min-width: 300px;
  transform: translateY(-70px);

  &.info {
    --background: white;
    --color: #adadaf;
    --button-color: #adadaf;
  }
  &.error {
    --background: #ee7b88;
    --color: white;
    --button-color: white;
  }
  &::part(message) {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
  &::part(icon) {
    font-size: 22px;
  }

  &::part(button) {
    height: auto;
    width: auto;
    font-size: 18px;
  }
}
