ion-toggle {
  height: 28px;
  width: 48px;
  border: 1px solid #e4e4e4;
  border-radius: 999px;

  --background: #f5f3ff;
  --background-checked: #f5f3ff;

  --handle-background: #c1bfdf;
  --handle-background-checked: #79da18;

  --handle-width: 28px;
  --handle-height: 28px;
  --handle-max-height: auto;
  --handle-spacing: 0px;

  --handle-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  --handle-border-radius: 999px;
  --handle-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
    0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13),
    0 3px 3px rgba(0, 0, 0, 0.05);

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}

ion-content::part(scroll) {
  overflow-y: auto !important;
}
