/**
  Переменные для отступов
 */

 $offset-1: 8px;
 $offset-2: 16px;
 $offset-3: 24px;
 $offset-4: 32px;
 $offset-5: 40px;
 $offset-6: 48px;

 $offset-form: 20px;

 /**
    Переменные для цветов
   */
 $black: #231f20;
 $primary-text-color: #333d49;
 $orange: #f7941f;

 $primary-color: #ff826a;
 $secont-theme: #9987ff;
 $defaul-gradient: red;
 $red-color: #ff5555;
 $ok-color: rgb(97, 172, 53);
 $magenta: #da6ab9;

 $reddish: #f92d6d;
 $max-screen: 520px;
 /**
    Переменные для шрифтов
   */

 $font-size-1: 12px;
 $font-size-2: 16px;
 $font-size-3: 18px;
 $font-size-4: 24px;
 $font-size-5: 36px;

 $z-modal: 556;
 $z-overlay: 555;
 $z-tutorial: 556;
 $z-tob-bar: 333;

 // NEW STYLES
 $salad-green: #79da18;
 $salad-green-light: #d8ffc2;
 $green: #0ad01d;
 $green-light: #7be971;
 $green-grey: #72dd97;
 $money-green: #82cf45;
 $violet: #7064ff;
 $violet-blue: #719ae9;
 $orange: #fd9c0b;
 $light-grey: #7d7d7d;
 $coral: #ff5d5d;
 $red: #ff2e2e;
 $yellow: #fff500;
 $light-grey: #f7f8fe;
 $orange-light: #feb445;
 $gold: #e1940b;
 $blue-grey-light: #e7ebf0;



 // BG images
 $promoChatDatifyLeftBgImageUrl: url(/assets/imgx/dating-promo/bokeh-left.png) no-repeat;
 $promoChatDatifyRightBgImageUrl: url(/assets/imgx/dating-promo/bokeh-right.png) no-repeat;
 $promoChatDatifyTextLayoutImageUrl: url(/assets/imgx/dating-promo/logo.png) no-repeat;
 $promoChatDatifyPhotosImageUrl: url(/assets/imgx/dating-promo/photos.png) no-repeat;
 $promoChatDatifyPhotos400ImageUrl: url(/assets/imgx/dating-promo/photos-uncut.png) no-repeat;
 $offersCommonPbcContainerImageUrl: url('/assets/imgx/bundle-5-secret-chats-bg.png');
 $gameBgLeft: url("assets/imgx/bg-left-girl.png");
 $gameBgRight: url("assets/imgx/bg-right-girl.png");
 $offersCommonBlackFridayLeftImgUrl: url(~src/assets/imgx/black-friday-left.png);

 $modal18plus-girl-1: url(/assets/imgx/18-plus-banner-girls/1.png);
 $modal18plus-girl-2: url(/assets/imgx/18-plus-banner-girls/2.png);
 $modal18plus-girl-3: url(/assets/imgx/18-plus-banner-girls/3.png);
 $modal18plus-girl-4: url(/assets/imgx/18-plus-banner-girls/4.png);
 $modal18plus-girl-5: url(/assets/imgx/18-plus-banner-girls/5.png);
 $modal18plus-girl-6: url(/assets/imgx/18-plus-banner-girls/6.png);
 $modal18plus-girl-7: url(/assets/imgx/18-plus-banner-girls/7.png);
 $modal18plus-girl-8: url(/assets/imgx/18-plus-banner-girls/8.png);
 $modal18plus-girl-9: url(/assets/imgx/18-plus-banner-girls/9.png);

 $modalTwoHotChatsGirlsModal: url(/assets/imgx/lesbi-offer-modal.png);
 $modalTwoHotChatsGirlsBanner: url(/assets/imgx/lesbi-offer-banner.png);
 $modalThreeGermanChatsModal: url(/assets/imgx/three-german-chats-modal.png);
 $modalThreeGermanChatsBanner: url(/assets/imgx/three-german-chats-banner.png);
